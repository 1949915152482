import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Introduction"
          subTitle="Using a combination of Gatsby and Contentful, Barcadia is ready to deploy and simple to use."
        />
        <BasicTextModule
          title="About"
          content="指定したチャンネルしか表示させません。"
        />
        <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule>
        <Features title="How to use it" introduction="使い方" />
      </Layout>
    </>
  )
}

export default Index
